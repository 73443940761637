import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Title from "../components/elements/title"
import SubTitle from "../components/elements/subtitle"
import ContactForm from "../components/form"
import ContactAddress from "../components/ContactAddress"
import Reviews from "../components/Reviews"

const ContactPage = () => (
  <Layout>
    <SEO title="Contact" />
    <div className="section">
      <div className="container">
        <Title title="Mulțumim pentru mesaj!" />
        <SubTitle subtitle="Te vom contacta în cel mai scurt timp posibil." />
      </div>
    </div>
    <div className="section">
      <div className="container">
        <div className="content address" data-sal="slide-up">
          <ContactForm />
          <ContactAddress />
        </div>
      </div>
    </div>
    <Reviews />
  </Layout>
)

export default ContactPage
